body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 730px;
  margin: 0 auto;
  text-align: center;
}

.box {
  float: left;
  width: 200px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f5f5f5;
  padding-bottom: 10px;
}

.box img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.box .name {
  padding: 10px 10px 5px 10px;
  font-weight: 600;
}

.box .email {
  font-style: italic;
  color: #666;
}

button.btn-load-more {
  padding: 10px 20px;
  margin: 20px 0 30px 0;
  cursor: pointer;
}

.clearfix {
  clear: both;
}